@font-face {
  font-family: "LevelOne";
  src: url("../fonts/LevelOne.woff") format('woff');
}

body {
  background-color: #dddddd;
}
section {
  margin-bottom: 3rem;
  box-shadow: 0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12);
  border-radius: 4px;
  background-color: #222222;
  padding: 10px 15px;
}

header {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../images/hero.jpg");
  height: 400px;
  background-repeat: no-repeat;
  background-position: center;
}

ul {
  font-family: LevelOne;
  font-size: 0.9em;
}

a {
  color: #59cbe8;
}

a:hover {
  color: #ef9d38;
}

header img {
  width: 300px;
  margin: 0 auto;
  display: block;
}
header b {
  color: #59cbe8;
}
header h1 {
  font-family: LevelOne, Helvetica;
  text-align: center;
  margin-top: 60px;
  font-size: 2.0em;
}
section h1 {
  font-family: LevelOne, Helvetica;
  text-transform: uppercase;
}
section h2 {
  font-family: LevelOne, Helvetica;
  text-transform: uppercase;
}
section p {
  font-family: LevelOne, Helvetica;
}
#portfolio {
  display: grid;
  grid-template: auto / auto auto;
  column-gap: 8px;
}
#portfolio div {
  margin: 2px;
}
#portfolio p {
  font-size: 0.8em;
  margin-left: 4px;
}
footer {
  display: grid;
  grid-template: auto / auto auto auto;
  color: #222222;
}
footer p {
  display: inline-grid;
  justify-content: center;
  font-size: 0.8em;
  font-family: Helvetica;
}

p.copyright { 
  font-size: 0.4em;
  margin: 0;
}

p.version {
  font-size: 0.4em;
  margin:0;
}

#cookiebanner {
  background-color: rgb(41, 124, 183);
  color: white;
  display: flex;
  justify-content: space-between;
  right: 0px;
  position: fixed;
  z-index: 999;
  width: 300px;
  bottom: 0px;
  border-radius: 8px;
  font-family: LevelOne;
  margin: 16px;
}

#cookiebanner span {
  margin: 15px;
}

#cookiebanner button {
  font-family: LevelOne;
}

span.icon-cookie {
  width: 80px;
  height: 40px;
  background-image: url("../images/cookie.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  position: relative;
}

span.icon-strategy {
  width: 40px;
  height: 40px;
  background-image: url("../images/strategy.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  position: relative;
  float: right;
  margin-top: 5px;
}

span.icon-development {
  width: 40px;
  height: 40px;
  background-image: url("../images/development.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  position: relative;
  float: right;
  margin-top: 5px;
}

span.icon-github {
  width: 30px;
  height: 30px;
  background-image: url("../images/github.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  position: relative;
  float: right;
  margin-top: 8px;
  margin-right: 8px;
}

span.icon-docs {
  width: 30px;
  height: 30px;
  background-image: url("../images/docs.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  position: relative;
  float: right;
  margin-top: 8px;
  margin-right: 8px;  
}

span.icon-osc {
  width: 30px;
  height: 30px;
  background-image: url("../images/osc-logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  position: relative;
  float: right;
  margin-top: 8px;
  margin-right: 8px;
}

span.icon-sustainability {
  width: 30px;
  height: 30px;
  background-image: url("../images/sustainability.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  position: relative;
  float: right;
  margin-top: 8px;
  margin-right: 8px;
  filter: invert(100%);
}

span.image-career {
  background-image: url("../images/career.png");
  display: block;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center; 
  width: 50%; 
  max-width: 300px; 
  height: auto;
  min-height: 290px;
  float: left;
  margin-right: 20px;
}

.logo-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0 auto;
}

ul.link-grid {
  display: grid;
  grid-template: auto / auto auto auto auto;
  margin: 0 auto;
  padding-left: 0px;
}

.link-grid li {
  list-style-type: none;
  margin-bottom: 10px;
  display: inline-grid;
  padding: 4px;
}

.link-grid a {
  color: white;
  text-decoration: none;
  border-radius: 4px;
  background-color:rgb(41, 124, 183);
  font-size: 1.2em;
  padding: 8px;
  color: white;
  text-align: center;
}

.social-grid {
  display: grid;
  grid-template: auto / auto auto auto;
  margin: 0 auto;
}

.social-instagram {
  width: 40px;
  height: 40px;
  background-image: url("../images/instagram.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  position: relative;
  margin: 0 auto;
}

.social-linkedin {
  width: 40px;
  height: 40px;
  background-image: url("../images/linkedin.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  position: relative;
  margin: 0 auto;
}

.social-X {
  width: 40px;
  height: 40px;
  background-image: url("../images/x.png");
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  position: relative;
  margin: 0 auto;
}

span.logo-dpp {
  background-image: url("../images/dpp.png");
  display: block;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 250px;
  height: 250px;
  margin: 0 auto;
}

span.logo-srt {
  background-image: url("../images/srt.png");
  display: block;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 250px;
  height: 250px;
  margin: 0 auto;
}

span.logo-ra {
  background-image: url("../images/ra.gif");
  display: block;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 250px;
  height: 250px;
  margin: 0 auto;
}

span.logo-ctawave {
  background-image: url("../images/cta-wave.png");
  display: block;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 250px;
  height: 250px;
  margin: 0 auto;
}
span.logo-svta {
  background-image: url("../images/svta.png");
  display: block;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 250px;
  height: 250px;
  margin: 0 auto;
}
span.logo-c2pa {
  background-image: url("../images/c2pa.png");
  display: block;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 250px;
  height: 250px;
  margin: 0 auto;
}

span.logo-dpp-ct {
  background-image: url("../images/dpp-ctsus.png");
  display: block;
  position: relative;
  float: right;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 120px;
  height: 120px;
  margin: 0 auto;
}

span.logo-dashif {
  background-image: url("../images/dashif-logo.jpg");
  display: block;
  position: relative;
  float: right;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 200px;
  margin: 0 auto;  
}

span.logo-ncp {
  background-image: url("../images/ncp-logo.jpg");
  display: block;
  position: relative;
  float: right;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 200px;
  margin: 0 auto;  
}

eyevinn-video {
  margin: 2px;
}

#contact-form {
  background-color: white;
  color: black;
  font-family: LevelOne;
  position: fixed;
  bottom: 20px;
  left: 40px;
  width: 500px;
  border-radius: 9px;
  z-index: 10;
  padding-top: 0.6em;
  padding-left: 0.6em;
  padding-right: 0.6em;

  transition: width 0.5s ease-in-out, bottom 0.5s ease-in-out;
}

#contact-form.collapsed {
  position: fixed;
  height: 40px;
  left: 40px;
  width: 100px;
  bottom: 0px;
  border-radius: 6px 6px 0px 0px;
  z-index: 10;
}

#contact-form small {
  font-size: 0.5em;
}

#contact-form p {
  font-size: 0.8em;
}

#contact-form label {
  display: inline-block;
}

#contact-form input {
  width: 100%;
  height: calc(1.5em + 0.75em + 2px);
  font-family: LevelOne;
  background-color: white;
  color: black;
  border: 1px solid #59cbe8;
}

#contact-form textarea {
  width: 100%;
  font-family: LevelOne;
  background-color: white;
  color: black;
  border: 1px solid #59cbe8;
}

#contact-form button {
  font-family: LevelOne;
}

#contact-form-button-open:hover {
  cursor:pointer;
}

.btn-osc-signup {
  color: white;
  text-decoration: none;
  border-radius: 4px;
  background-color:rgb(41, 124, 183);
  font-size: 1.2em;
  padding: 8px;
  color: white;
  text-align: center;
}

@media (max-width: 600px) {
  #portfolio {
    display: grid;
    grid-template: auto / auto;
  }

  .logo-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(5, 1fr);
    margin: 0 auto;
  }

  ul.link-grid {
    display: grid;
    grid-template: auto / auto;
    margin: 0 auto;
  }
  
  #contact-form {
    width: 300px;
  }

  span.image-career {
    width: 100%;
  }

  footer {
    display: grid;
    grid-template: auto / auto;
  }
  
  footer p {
    font-size: 0.8em;
  }  
}